var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap container_upload_pop",
      staticStyle: { width: "1200px", height: "750px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v("Container Information Excel Upload"),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("div", { staticClass: "text_right" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.002"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "txt_bar2" }),
            _c("span", [_vm._v(_vm._s(_vm.parentInfo.blNo))]),
          ]),
          _vm.usrCtrCd !== "JP" &&
          _vm.usrCtrCd !== "PH" &&
          _vm.usrCtrCd !== "VN"
            ? _c("table", { staticClass: "tbl_col" }, [
                _vm._m(0),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.197")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.252")))]),
                    _c("th", [_vm._v("Empty")]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.060")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.061")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.251")))]),
                    _c("th"),
                  ]),
                ]),
                _vm.containerList.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.containerList, function (vo, idx) {
                        return _c("tr", { key: idx }, [
                          _c("td", { class: _vm.isRed(vo, "cntrNo") }, [
                            _vm._v(_vm._s(vo.cntrNo)),
                          ]),
                          _c("td", { class: _vm.isRed(vo, "cntrSz") }, [
                            _vm._v(_vm._s(vo.cntrSzCd)),
                          ]),
                          _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                          _c("td", { class: _vm.isRed(vo, "shprVanYn") }, [
                            _vm._v(_vm._s(vo.shprVanYn)),
                          ]),
                          _c("td", { class: _vm.isRed(vo, "feUnitCd") }, [
                            _vm._v(_vm._s(vo.feUnitCd)),
                          ]),
                          _c("td", [_vm._v(_vm._s(vo.wt))]),
                          _c("td", [_vm._v(_vm._s(vo.pkgQty))]),
                          _c(
                            "td",
                            { staticClass: "text_left" },
                            _vm._l(vo.sealList, function (svo, i) {
                              return _c("p", { key: i }, [
                                _vm._v(
                                  " Seal No. " +
                                    _vm._s(i + 1) +
                                    " : " +
                                    _vm._s(svo.sealNo) +
                                    " "
                                ),
                              ])
                            }),
                            0
                          ),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeBLContainer(idx)
                                  },
                                },
                              },
                              [_vm._v(" minus ")]
                            ),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "8" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CCIT100.034"))),
                        ]),
                      ]),
                    ]),
              ])
            : _vm._e(),
          _vm.usrCtrCd === "PH" || _vm.usrCtrCd === "VN"
            ? _c("table", { staticClass: "tbl_col" }, [
                _vm._m(1),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.197")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.252")))]),
                    _c("th", [_vm._v("Empty")]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.060")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX010P090.061")))]),
                    _c("th", [_vm._v("CBM")]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.251")))]),
                    _c("th"),
                  ]),
                ]),
                _vm.containerList.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.containerList, function (vo, idx) {
                        return _c("tr", { key: idx }, [
                          _c("td", { class: _vm.isRed(vo, "cntrNo") }, [
                            _vm._v(_vm._s(vo.cntrNo)),
                          ]),
                          _c("td", { class: _vm.isRed(vo, "cntrSz") }, [
                            _vm._v(_vm._s(vo.cntrSzCd)),
                          ]),
                          _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                          _c("td", { class: _vm.isRed(vo, "shprVanYn") }, [
                            _vm._v(_vm._s(vo.shprVanYn)),
                          ]),
                          _c("td", { class: _vm.isRed(vo, "feUnitCd") }, [
                            _vm._v(_vm._s(vo.feUnitCd)),
                          ]),
                          _c("td", [_vm._v(_vm._s(vo.wt))]),
                          _c("td", [_vm._v(_vm._s(vo.pkgQty))]),
                          _c("td", [_vm._v(_vm._s(vo.meaCbm))]),
                          _c(
                            "td",
                            { staticClass: "text_left" },
                            _vm._l(vo.sealList, function (svo, i) {
                              return _c("p", { key: i }, [
                                _vm._v(
                                  " Seal No. " +
                                    _vm._s(i + 1) +
                                    " : " +
                                    _vm._s(svo.sealNo) +
                                    " "
                                ),
                              ])
                            }),
                            0
                          ),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeBLContainer(idx)
                                  },
                                },
                              },
                              [_vm._v(" minus ")]
                            ),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "9" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CCIT100.034"))),
                        ]),
                      ]),
                    ]),
              ])
            : _vm._e(),
          _vm.usrCtrCd === "JP"
            ? _c("table", { staticClass: "tbl_col" }, [
                _vm._m(2),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.197")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.252")))]),
                    _c("th", [_vm._v("Cargo Wgt")]),
                    _c("th", [_vm._v("Tare Wgt")]),
                    _c("th", [_vm._v("Pkg")]),
                    _c("th", [_vm._v("Measure")]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBL200.251")))]),
                    _c("th"),
                  ]),
                ]),
                _vm.containerList.length > 0
                  ? _c(
                      "tbody",
                      _vm._l(_vm.containerList, function (vo, idx) {
                        return _c("tr", { key: idx }, [
                          _c("td", { class: _vm.isRed(vo, "cntrNo") }, [
                            _vm._v(_vm._s(vo.cntrNo)),
                          ]),
                          _c("td", { class: _vm.isRed(vo, "cntrSz") }, [
                            _vm._v(_vm._s(vo.cntrSzCd)),
                          ]),
                          _c("td", [_vm._v(_vm._s(vo.cntrTypCd))]),
                          _c("td", { class: _vm.isRed(vo, "shprVanYn") }, [
                            _vm._v(_vm._s(vo.shprVanYn)),
                          ]),
                          _c("td", [_vm._v(_vm._s(vo.wt))]),
                          _c("td", [_vm._v(_vm._s(vo.tareWt))]),
                          _c("td", [_vm._v(_vm._s(vo.pkgQty))]),
                          _c("td", [_vm._v(_vm._s(vo.meaCbm))]),
                          _c(
                            "td",
                            { staticClass: "text_left" },
                            _vm._l(vo.sealList, function (svo, i) {
                              return _c("p", { key: i }, [
                                _vm._v(
                                  " Seal No. " +
                                    _vm._s(i + 1) +
                                    " : " +
                                    _vm._s(svo.sealNo) +
                                    " "
                                ),
                              ])
                            }),
                            0
                          ),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeBLContainer(idx)
                                  },
                                },
                              },
                              [_vm._v(" minus ")]
                            ),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _c("tbody", [
                      _c("tr", [
                        _c("td", { attrs: { colspan: "9" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CCIT100.034"))),
                        ]),
                      ]),
                    ]),
              ])
            : _vm._e(),
          _c("table", { staticClass: "tbl_row mt10" }, [
            _vm._m(3),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.019")))]),
                _c("td", { staticClass: "text_left" }, [
                  _c("span", [
                    _c("input", {
                      attrs: { type: "file", id: "inpFile" },
                      on: {
                        change: function ($event) {
                          return _vm.fnFileUpload()
                        },
                      },
                    }),
                  ]),
                  _c("span", [
                    _c(
                      "a",
                      {
                        staticClass: "button sm",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.excelUpload.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.020")))]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "button sm ml5",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.excelDownload.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.021")))]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.containerUpload()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.CSBL200.181")))]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg ml5",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.028")))]
            ),
          ]),
        ]),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "winLayerPop" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popupParams },
                on: {
                  close: _vm.layerClose,
                  "set-dext-file-info": _vm.arrangeUploadParam,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "15%" } }),
      _c("col", { staticStyle: { width: "7%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "21%" } }),
      _c("col", { staticStyle: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "7%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "8%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "21%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "80%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }